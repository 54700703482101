
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/landings/become-host/[[...campaign]]",
      function () {
        return require("private-next-pages/landings/become-host/[[...campaign]].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/landings/become-host/[[...campaign]]"])
      });
    }
  