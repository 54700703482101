import React from 'react'
import { prismicPropTypes } from 'helpers/proptypes'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { fetchPrismicBecomeHostPage } from 'services/prismic'
import { initApplication } from 'helpers/SSR'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'
import {
  Head,
  PrismicHeroSection,
  ListSection,
  HighlightsSection,
  ReviewsSection,
  TestimonialsSection,
  FaqSection,
  CTASection,
} from 'components/shared/prismic'
import { cacheRules } from 'helpers/cache'
import { COOKIE_KEYS } from 'helpers/cookies'

// TODO: this should be a getStaticProps but it needs a getStaticPaths because of the catch all route
// Difficulty in querying all the single occurences of an URL given that each element by language is
// returned by the API
export const getServerSideProps = async ({ locale, previewData, params: { campaign }, query, res, req }) => {
  const cookieCurrency = req.cookies[COOKIE_KEYS.EW_CURRENCY]
  initApplication({ currency: query.c || cookieCurrency, locale })

  const data = await fetchPrismicBecomeHostPage({ locale, previewData, campaign })
  if (!data?.length) return { notFound: true }

  res.setHeader('Cache-Control', cacheRules.landingPage)

  return {
    props: {
      data,
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}

export default function BecomeHostPage({ data }) {
  return (
    <DefaultLayout>
      <Header isTransparent />
      {data.map((section, i) => {
        switch (section.slice_type) {
          case 'seo_metadata':
            return <Head key={section.slice_type} staticFields={section.primary} />
          case 'hero_section':
            return <PrismicHeroSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'list_section':
            return (
              <ListSection key={`${section.slice_type}-${i}`} staticFields={section.primary} items={section.items} />
            )
          case 'highlight_section':
            return <HighlightsSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'reviews_section':
            return (
              <ReviewsSection key={`${section.slice_type}-${i}`} staticFields={section.primary} items={section.items} />
            )
          case 'testimonial_section':
            return (
              <TestimonialsSection
                key={`${section.slice_type}-${i}`}
                staticFields={section.primary}
                items={section.items}
              />
            )
          case 'cta_section':
            return <CTASection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
          case 'faq_section':
            return (
              <FaqSection key={`${section.slice_type}-${i}`} staticFields={section.primary} items={section.items} />
            )
          default:
            return null
        }
      })}
      <Footer />
    </DefaultLayout>
  )
}

BecomeHostPage.propTypes = prismicPropTypes
